<template>
  <v-footer
    style="background: #000"
    padless
  >
    <v-container>
      <v-row class="mt-16 px-xs-2">
        <v-col cols="12" md="4">
          <v-btn
            icon
            large
            outlined
            color="accent"
            class="mr-2"
            href="https://www.facebook.com/Lava-For-Good-103723765430985"
          >
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn
            dark
            icon
            large
            outlined
            color="accent"
            class="mr-2"
            href="https://www.instagram.com/lavaforgood/"
          >
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
          <!--
          <v-btn
            dark
            icon
            large
            outlined
            color="accent"
            class="mr-2"
            href="https://twitter.com/LavaForGood"
          >
            <v-icon>mdi-twitter</v-icon>
          </v-btn>
          -->
          <v-btn
            icon
            large
            outlined
            color="accent"
            class="mr-2"
            href="https://www.tiktok.com/@lavaforgood?is_from_webapp=1&sender_device=pc"
          >
            <v-icon>$vuetify.icons.tiktokicon</v-icon>
          </v-btn>
          <v-btn
            icon
            large
            outlined
            color="accent"
            class="mr-2"
            href="https://www.youtube.com/channel/UCJmOImwr_06LQBnS702xGcQ/featured"
          >
            <v-icon>mdi-youtube</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="4"><h3>SIGN UP</h3>Get updates and notifications for new episodes.</v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="emailAddress"
            label="Enter email address"
            :hint="hint"
            persistent-hint
            filled
            clearable
            background-color="#464646"
            class="mr-3 rounded-0 float-left"
            style="width: 70%"
          >
          </v-text-field>
          <v-btn
            absolute
            tile
            color="primary"
            height=56
            @click="emailSignUp"
          >
            Sign Up
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import axios from 'axios'
export default {
  name: 'PageSignUp',
  data: () => ({
    emailAddress: '',
    hint: '',
  }),
  methods: {
    emailSignUp: function () {
      if (/.+@.+\..+/.test(this.emailAddress)) {
        //axios.post('https://lavaforgood.com/bin/list.cgi',
        axios.post('/api/v1/list?email=' + this.emailAddress + '&page=home')
        //axios.get('/api/v1/list?email=' + this.emailAddress + '&page=home')
          .then((response) => {
            if (response.data.success) {
              this.emailAddress = ''
              this.hint = "You're in!"
            }
          })
          .catch(function (error) {
            console.log(error)
          });
      } else {
        this.hint = "Please enter a valid email address."
      }
    },
  }
}
</script>
<style>
h3 {
  font-family: "Geogrotesque", sans-serif;
  color: #EA1301;
}
.hi-btn {
  color: white;
}
</style>

